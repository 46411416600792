import React, { useState } from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { differenceInCalendarDays, addDays, format } from "date-fns"; // @TODO: Keep format; use for fitplanValidUntil.
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import Card from "./card";
import ProfileImage from "./ProfileImage";
import Icon from "../../components/icons";
import FitplanIcon from "./fitplan-icon";
import Mealplan from "./mealplan-page";
import AccountLayout from "./account-layout";
import Layout from "../../components/layout/layout";
import "./account.scss";
import { baseTextStyle } from "../../lib/components/NewSubscriptionModal/elements";
import { navigateTo } from "../../lib/UrlUtils";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const Account = ({ data: { ribbon, header, footer } }) => {
  const { t, language } = useTranslation();
  const authContext = useAuthContext();
  const { loggedIn, user } = authContext;
  if (!loggedIn) {
    return navigateTo("/login");
  }
  const profilePic = user.profileImage;
  const accountData = {
    editProfile: "Edit Profile",
    name: "name",
    email: "email",
    gender: "gender",
    password: "password",
    manageSubscription: "manageSubscription",
    member: "member",
    nonMember: "nonMember",
  };
  const expiration = new Date(user.displayExpirationTimeStamp).toDateString();
  const StoreType =
    user.paymentStoreType == 1
      ? "IOS Premium account"
      : user.paymentStoreType == 2
      ? "Google Premium account"
      : user.paymentStoreType == 99
      ? "Ultra Premium"
      : "Web Premium Account"; // 1 apple 2 google 3 stripe
  // Days since joining Fitplan.
  const { dateJoined } = user;
  const daysOnFitplan = differenceInCalendarDays(
    new Date(),
    new Date(dateJoined)
  );

  const userName = `${user.firstName ? user.firstName : ""}${
    user.lastName ? `  ${user.lastName}` : ""
  }`;
  let fitplanValidUntil = "Free User";
  if (user.paymentExpirationTimestamp != 0) {
    const today = new Date();
    const daysDiff = differenceInCalendarDays(
      new Date(user.displayExpirationTimeStamp),
      today
    );

    const expires = format(addDays(today, daysDiff), "MMMM d, yyyy");
    if (daysDiff < 0) {
      fitplanValidUntil = `Expired on ${expires}.`;
    } else {
      fitplanValidUntil = `Valid until ${expires}`;
    }
  }

  const isMealplanMember = user.isMealplanMember;
  const isFitplanMember =
    user.paymentStoreType !== 0 && user.paymentExpirationTimestamp !== 0;

  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <section className="account-page">
        <div className="account-container">
          <AccountLayout currentPage="/account">
            <Card
              mainLinkLabel={t("editProfile")}
              className="wide_card"
              mainLink="/account/profile"
              mainLinkIcon="pencil"
            >
              <div
                css={css`
                  display: grid;
                  grid-template-rows: 40% 60%;
                  @media (min-width: 900px) {
                    margin-top: -18px;
                    display: flex;
                  }
                `}
              >
                <div
                  css={css`
                    width: 236px;
                  `}
                >
                  <ProfileImage size={120} imageUrl={profilePic} />
                  <h2
                    css={css`
                      ${baseTextStyle}
                      font-size: 24px;
                      font-weight: bold;
                      font-stretch: condensed;
                      line-height: 1.25;
                      margin-bottom: 16px;
                      margin-top: 14px;
                    `}
                  >
                    {userName}
                  </h2>
                  <p css={baseTextStyle}>
                    {daysOnFitplan} Day
                    {daysOnFitplan !== 1 ? "s" : ""} {t("onFitplan")}
                    {/* ADD THIS BACK ONCE DATA IS AVAILABLE */}
                    {/*  <br />
                    {fitplansCompleted} Fitplans Completed */}
                  </p>
                </div>
                <div
                  css={css`
                    @media (max-width: 900px) {
                      display: flex;
                      flex-direction: column;
                    }
                    margin-bottom: 50px;
                    p {
                      ${baseTextStyle}
                      font-size: 12px;
                      margin-top: 24px;
                      min-width: 250px;
                      @media (max-width: 350px) {
                        min-width: 200px;
                      }
                    }
                    input {
                      ${baseTextStyle}
                      border: 0;
                      margin-top: 8px;
                      min-width: 270px;
                      overflow-x: hidden;
                      background: none;
                      @media (max-width: 350px) {
                        min-width: 200px;
                      }
                    }
                  `}
                >
                  <p>{t("name")}</p>
                  <input value={userName} disabled={true} />
                  <p>{t("email")}</p>
                  {/* @TODO: verify this will always be an email address or not */}
                  <input value={user.username} disabled={true} />{" "}
                  <p>{t("gender")}</p>
                  <input value={user.gender} disabled={true} />{" "}
                  {/* @TODO: need lookup for gender display text */}
                  <p>{t("password")}</p>
                  <input value="••••••••" disabled={true} />
                </div>
              </div>
            </Card>
            <Card
              titleText="Fitplan"
              className="wide_card"
              SVGIcon={<FitplanIcon width="40px" />}
              mainLink="/account/subscription"
              mainLinkLabel={t("manageSubscription")}
              mainLinkIcon="credit-card-icon"
            >
              <div
                css={css`
                  display: flex;
                  height: 70px;
                  width: 236px;
                  margin-top: 20px;
                  margin-bottom: 40px;
                  @media (max-width: 768px) {
                    display: block;
                    height: 134px;
                    width: 100%;
                  }
                `}
              >
                {isFitplanMember ? (
                  <div
                    css={css`
                      display: flex;
                    `}
                  >
                    <svg
                      viewBox="0 0 42 38"
                      css={css`
                        width: 26px;
                        height: 24px;
                        margin-bottom: -5px;
                      `}
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#12B587"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      >
                        <path d="M36.65 16.17c.14.899.212 1.823.212 2.761 0 9.903-8.028 17.931-17.931 17.931S1 28.834 1 18.931 9.028 1 18.931 1c2.985 0 5.799.728 8.276 2.02" />
                        <path d="M10.655 14.793l8.276 8.276L41 1" />
                      </g>
                    </svg>
                    <span
                      css={css`
                        margin-left: 16px;
                        ${baseTextStyle}
                        font-stretch: condensed;
                        line-height: 1.14;
                        font-weight: bold;
                        @media (min-width: 350px) {
                          width: 200px;
                        }
                        @media (max-width: 768px) {
                          margin-bottom: 20px;
                        }
                      `}
                    >
                      {t("member")}
                    </span>
                  </div>
                ) : (
                  <div
                    css={css`
                      display: flex;
                    `}
                  >
                    <svg
                      viewBox="0 0 42 38"
                      css={css`
                        width: 26px;
                        height: 32px;
                        margin-bottom: -5px;
                      `}
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#FC172F"
                        strokeLinecap="square"
                        strokeWidth="2"
                        transform="translate(1 1.603)"
                      >
                        <path d="M28 12L12 28M28 28L12 12" />
                        <circle cx="20" cy="20" r="20" />
                      </g>
                    </svg>
                    <span
                      css={css`
                        margin-left: 16px;
                        ${baseTextStyle}
                        font-weight: bold;
                        font-stretch: condensed;
                        line-height: 1.14;

                        @media (min-width: 350px) {
                          width: 200px;
                        }
                      `}
                    >
                      {t("nonMember")}
                    </span>
                  </div>
                )}
                {isFitplanMember && <div
                  css={css`
                    width: 300px;
                    @media (max-width: 768px) {
                      width: 100%;
                    }
                    p {
                      ${baseTextStyle}
                      white-space: nowrap;
                      margin-bottom: 8px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  `}
                >
                  <p>Type: {StoreType}</p>
                  {StoreType != "Ultra Premium" ? (
                    <p>{fitplanValidUntil}</p>
                  ) : (
                    ""
                  )}
                  {user && user.giftCards && user.giftCards.length > 0 ? (
                    <div
                      css={css`
                        ${baseTextStyle}
                        font-weight: bold;
                        white-space: nowrap;
                        margin-bottom: 8px;
                        text-overflow: ellipsis;
                      `}
                    >
                      Redeemed Gift Cards:{" "}
                      {user.giftCards.map((giftCard) => (
                        <span
                          css={css`
                            ${baseTextStyle}
                            border: 1px solid #ccc;
                            border-radius: 2px;
                            padding: 4px;
                            margin: 0 4px;

                            :hover {
                              border: 1px solid #111213;
                            }
                          `}
                        >
                          {giftCard.giftCardCode}
                        </span>
                      ))}
                      {(user.paymentStoreType === 1 ||
                        user.paymentStoreType === 2) && (
                        <div
                          css={css`
                            margin-top: 8px;
                            font-weight: normal;
                          `}
                        >
                          Fitplan subscribers who redeem a Gift Card must manage
                          their subscription in the{" "}
                          {user.paymentStoreType === 1
                            ? "Apple App Store"
                            : user.paymentStoreType === 2
                            ? "Google Play Store"
                            : ""}{" "}
                          to avoid recurring charges.
                        </div>
                      )}
                    </div>
                  ) : (
                    <div />
                  )}
                </div>}
              </div>{" "}
            </Card>
          </AccountLayout>
        </div>
      </section>
    </Layout>
  );
};

export default Account;
