import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import PropTypes from "prop-types";

class ProfileImagePlaceholder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <svg
        css={css`
          width: ${this.props.size}px;
          height: ${this.props.size}px;
          ${this.props.margin && `margin: ${this.props.margin}`};
          display: ${this.props.display};
        `}
        viewBox="0 0 124 124"
      >
        <g
          fill="none" // fill-rule="evenodd"
          stroke={this.props.color}
          strokeWidth="3"
          transform="translate(2 2)"
        >
          <path d="M60 81.818c-12.05 0-21.818-9.769-21.818-21.818V49.09c0-12.048 9.769-21.817 21.818-21.817 12.05 0 21.818 9.769 21.818 21.818V60c0 12.05-9.769 21.818-21.818 21.818z" />
          <path d="M93.286 109.928c-6.002-7.055-15.114-11.746-25.104-11.746H51.818c-10.074 0-19.085 4.552-25.088 11.71" />
          <circle cx="60" cy="60" r="60" strokeLinecap="square" />
        </g>
      </svg>
    );
  }
}

ProfileImagePlaceholder.defaultProps = {
  size: 80,
  color: "#000",
  margin: null,
  display: "block",
};

ProfileImagePlaceholder.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  margin: PropTypes.string,
  display: PropTypes.string,
};

export default ProfileImagePlaceholder;
