import * as React from "react";
import { Link } from "../../lib/components/Link";
import { navigateTo } from "../../lib/UrlUtils";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import "../../tailwind.scss";

export interface Props {
  currentPage: string;
}

const AccountLayout: React.FunctionComponent<Props> = ({
  currentPage,
  children,
}) => {
  const account = {
    accountNavbar: {
      accountOverview: "Account Overview",
      editProfile: "Edit Profile",
      subscription: "Subscription",
    },
  };

  const { t } = useTranslation();
  const { languages, changeLanguage } = useI18next();

  return (
    <div
      style={{
        fontFamily: `"Barlow", "Helvetica Neue", Helvetica, Arial, sans-serif`,
      }}
    >
      <div className="sm:hidden p-4">
        <select
          aria-label="Selected tab"
          className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
          value={currentPage}
          onChange={(event) => {
            navigateTo(event.target.value);
          }}
        >
          <option value={"/account"}>{t("accountOverview")}</option>
          <option value={"/account/profile"}>{t("editProfile")}</option>
          <option value={"/account/subscription"}>{t("Subscription")}</option>
          {/* <option value={"/account/redeem"}>{t("redeemCode")}</option> */}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200 px-8">
          <nav className="-mb-px flex">
            <Link
              to="/account"
              className={currentPage === "/account" ? classSelected : classMenu}
            >
              {t("accountOverview")}
            </Link>
            <Link
              to="/account/profile"
              className={`ml-8 ${
                currentPage === "/account/profile" ? classSelected : classMenu
              }`}
            >
              {t("editProfile")}
            </Link>
            <Link
              to="/account/subscription"
              className={`ml-8 ${
                currentPage === "/account/subscription"
                  ? classSelected
                  : classMenu
              }`}
            >
              {t("Subscription")}
            </Link>
            {/* <Link
              to="/account/redeem"
              className={`ml-8 ${
                currentPage === "/account/redeem" ? classSelected : classMenu
              }`}
            >
              {t("redeemCode")}
            </Link> */}
          </nav>
        </div>
      </div>
      {children}
    </div>
  );
};

const classSelected =
  "whitespace-no-wrap py-6 px-1 border-b-2 border-brand-500 font-bold text-xl leading-5 text-brand-400 focus:outline-none focus:text-brand-500 focus:border-brand-700";
const classMenu =
  "whitespace-no-wrap py-6 px-1 border-b-2 border-transparent font-bold text-xl leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300";

export default AccountLayout;
